import store from '../store';

const getAdminMenu = function () {
    const user = store.getters.currentUser;

    const adminMenu = [
        {
            text: 'Common Pages',
            link: '#',
            items: [
                {
                    text: 'Master Data',
                    link: '/admin/master-data/'
                },
                {
                    text: 'Social Media',
                    link: '/admin/social-media/'
                },
                {
                    text: 'Banners',
                    link: '/admin/banner/'
                },
                {
                    text: 'Terms & Privacy',
                    link: '/admin/terms-and-privacy/'
                }
            ]
        },
        {
            text: 'Home',
            link: '#',
            items: [
                {
                    text: 'Why Choose Us',
                    link: '/admin/why-choose-us/'
                },
                {
                    text: 'Training Centers',
                    link: '/admin/training-centers/'
                },
                {
                    text: 'Our Facility',
                    link: '/admin/our-facility/'
                },
                {
                    text: 'Training Requests',
                    link: '/admin/training-requests/'
                }
            ]
        },
        {
            text: 'About Us',
            link: '#',
            items: [
                {
                    text: 'About Us',
                    link: '/admin/about-us/'
                },
                {
                    text: 'Philosophy',
                    link: '/admin/philosophy/'
                },
                {
                    text: 'Training Schedule',
                    link: '/admin/training-schedule/'
                }
            ]
        },
        {
            text: 'Courses',
            link: '/admin/courses/'
        },
        {
            text: 'Payments',
            link: '#',
            items: [
                {
                    text: 'Course Register',
                    link: '/admin/course-register/'
                },
                {
                    text: 'Transactions',
                    link: '/admin/transactions/'
                }
            ]
        },
        {
            text: 'News',
            link: '/admin/news/'
        },
        {
            text: 'Contact Us',
            link: '#',
            items: [
                {
                    text: 'Contact Us Page',
                    link: '/admin/contact-us-page/'
                },
                {
                    text: 'Contact Us Requests',
                    link: '/admin/contact-us/'
                }
            ]
        }
    ];
    // Do something similar for any number of roles
    if (user && user.is_superuser === true) {
        return [
            ...adminMenu
        ];
    }
    return [
        ...adminMenu
    ];
};
export default getAdminMenu;
